// Images file for 'homeroofingsurvey'.
// The export of 'Favicon' and 'Logo' must match those names exactly.
// Use 'images.js' in the 'harpquiz' directory as a guide.

import Favicon from './assets/favicon.ico';
import Dropdown from '../../../../src/images/dropdown_carat.png';
import Checked from './assets/checked.png';
import Unchecked from './assets/unchecked.png';
import Checked2 from './assets/checked2.png';
import Unchecked2 from './assets/unchecked2.png';
import BackArrow from './assets/back-arrow.png';
// roofing images
import Asphalt from './assets/roofLogos/asphalt.jpg';
import Tile from './assets/roofLogos/tile.jpg';
import Metal from './assets/roofLogos/metal.jpg';
import Foam from './assets/roofLogos/foam.jpg';
import TarGravel from './assets/roofLogos/tarGravel.jpg';
import WoodShingle from './assets/roofLogos/woodShingle.jpg';
import Slate from './assets/roofLogos/slate.jpg';
import AsphaltB from './assets/AltRoofImages/asphalt_B.png';
import TileB from './assets/AltRoofImages/tile_B.png';
import MetalB from './assets/AltRoofImages/metal_B.png';
import FoamB from './assets/AltRoofImages/foam_B.png';
import TarGravelB from './assets/AltRoofImages/tarGravel_B.png';
import WoodShingleB from './assets/AltRoofImages/woodShingle_B.png';
import SlateB from './assets/AltRoofImages/slate_B.png';
import AsphaltSVG from './assets/AltRoofImages/asphalt.svg';
import TileSVG from './assets/AltRoofImages/clay.svg';
import MetalSVG from './assets/AltRoofImages/metal.svg';
import FoamSVG from './assets/AltRoofImages/foam.svg';
import TarGravelSVG from './assets/AltRoofImages/gravel.svg';
import WoodShingleSVG from './assets/AltRoofImages/wood.svg';
import SlateSVG from './assets/AltRoofImages/slate.svg';
import Question from './assets/roofLogos/Question.jpg';
import House from './assets/HouseWCircle.svg';
import Notepad from './assets/NotepadWCircle.svg';
import People from './assets/PeopleWCircle.svg';

export default {
  Favicon,
  Dropdown,
  Checked,
  Unchecked,
  Checked2,
  Unchecked2,
  BackArrow,
  Asphalt,
  Tile,
  Metal,
  Foam,
  TarGravel,
  WoodShingle,
  Slate,
  AsphaltB,
  TileB,
  MetalB,
  FoamB,
  TarGravelB,
  WoodShingleB,
  SlateB,
  AsphaltSVG,
  TileSVG,
  MetalSVG,
  FoamSVG,
  TarGravelSVG,
  WoodShingleSVG,
  SlateSVG,
  Question,
  House,
  Notepad,
  People,
};
